import React, { useCallback, useState } from 'react';
import useWordSource from "../../hooks/use-word-source-hook";
import ThemeToggle from "../theme-toggle";
import { QuestionMarkCircleIcon } from '@heroicons/react/24/outline';  // Importing outlined icons from Heroicons v2
import "./style.css";

// Creating a React component for the Help icon with specific styling
const HelpQuestionMark = () => <QuestionMarkCircleIcon className="transparent-icon" />

export default function Header() {
  const { wordsource, wordsources, setWordsource } = useWordSource();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleWordsourceChange = useCallback((e) => {
    setWordsource(e.target.value);
  }, [setWordsource]);

  const toggleModal = () => setIsModalVisible(!isModalVisible);

  return (
    <>
      <header className="header">
        <nav>
          <ul>
            <li style={{ display: 'flex', flexDirection: 'row'}}>
              <div>
                <h1 className="textColorNUSblue">PharMan by</h1>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <h1 className="textColorNUSblue">Pharma</h1>
                    <h1 className="textColorNUSorange">NUS</h1>
                  </div>
                <small className="Subtitle">Medicines hangman</small>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', marginLeft: "10rem" }}>
                <span role="button">
                  <ThemeToggle />
                </span>
                <span role="button" className="help-toggle neumorph" onClick={toggleModal}>
                  <HelpQuestionMark />
                </span>
              </div>
            </li>
          </ul>
          </nav>
          <nav>
          <div>
            <ul>
              <li>
                <div style={{ display: 'flex', alignItems: 'center' }}>  {/* This div groups label and select */}
                  <p style={{ margin: '0 10px 0 0', fontSize: 'small' }}>Select topic:</p>
                  <select
                    id="wordsource"
                    className="neumorph"
                    value={wordsource}
                    onChange={handleWordsourceChange}
                  >
                    {wordsources.map((source) => (
                      <option key={source}>{source}</option>
                    ))}
                  </select>
                </div>
              </li>
            </ul>
          </div>
        </nav>
      </header>



      {isModalVisible && (
        <div id="popupHelp" className="modal">
          <div className="modal-content">
            <span className="close" onClick={toggleModal}>&times;</span>
            <p>  
             This is a version of the well known <a href="https://en.wikipedia.org/wiki/Hangman_(game)" title="Wikpedia: Hangman (game)"> hangman game</a>{" "}developed 
             to help medical and health professions students actively recall and learn to correctly spell the names of commonly prescribed drugs. 
             </p>
             <br></br>
             <p>
             <strong>Mission Brief:</strong> Your patient is in critical need of a medication refill, and it’s up to you to ensure they get it. But there’s a twist! 
             You must correctly spell the name of the drug they need. Each incorrect guess causes the plasma drug concentration to dip dangerously low—threatening 
             to fall below the therapeutic window.
            </p>
            <br></br>
            <p>
             <strong>Your Objective:</strong> Keep your patient stable by accurately spelling the drug name before the plasma concentration drops below 
             the minimum effective concentration. Each letter counts in this high-stakes scenario, where precision can save lives.             
             {/* Your "patient" has run out of medicine and you need to spell the drug name correctly for a repeat prescription. Each time you guess a wrong letter the plasma drug concentration drops from steady state. Correctly spell the drug name before the plasma drug concentration falls below the minimum 
             effective concentration.*/}           
             </p>
             <p>
            <div class="center-divider"></div>
            </p>
             <p>
             This project was built using <a href="https://reactjs.org/" title="React"> React</a>. 
             Code adapted by <a href="https://blog.nus.edu.sg/phcdgs/resources-for-learners/" title="PharmaNUS"> PharmaNUS</a>
              {" "}from Github:{" "}<a href="https://github.com/vijayjangid/react-hangman" title="Github React-Hangman">
              @vijayjangid/react-hangman</a>.
              Icons are from the <a href="https://heroicons.com/" title="Heroicons"> Heroicons</a>.
              </p>
          </div>
        </div>
      )}
    </>
  );
}
