import "./style.css";
import { GAME_RESULT } from "../../constants";

export default function Result({ result, guessword }) {
  let resultText = "😊",
      containerClass = "neumorph invert disabled result";

  let resultClass = ""; // Reactivated this variable to manage CSS classes

  if (result === GAME_RESULT.WON) {
    resultClass = "success";
    resultText = "🥳 Congratulations!!! You WON.";
  } else if (result === GAME_RESULT.LOST) {
    resultClass = "error"; 
    resultText = `😞 '${guessword}'`;
  } else {
    resultClass = "null"; 
    resultText = "━━━━━━━━━━━━━━━━";
    containerClass = "result"; // Simplify class when no result
  }

  return (
    <div className={`${containerClass} ${resultClass}`}>
      {resultText}
    </div>
  );
}
