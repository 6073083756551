export const GAME_RESULT = {
  IN_PROGRESS: " ",
  LOST: "Lost",
  WON: "Won"
};
export const MAX_WRONG_GUESSES = 7;
/*export const TESTWORDLENGTH = [
  "ABCDEFGHIJKLMNOPQ",
  "BBCDEFGHIJKLMNOPQ",
  "CBCDEFGHIJKLMNOPQ",
  "DBCDEFGHIJKLMNOPQ",
]*/
export const FOUNDATIONAL = [
  "HYDROCORTISONE",
  "PREDNISOLONE",
  "TRIAMCINOLONE",
  "DEXAMETHASONE",
  "BETAMETHASONE",
  "FLUDROCORTISONE",
  "ASPIRIN",
  "KETOPROFEN",
  "PIROXICAM",
  "NAPROXEN",
  "IBUPROFEN",
  "DILOFENAC",
  "INDOMETACIN",
  "CELECOXIB",
  "PARECOXIB",
  "ETORICOXIB",
  "PARACETAMOL",
  "AZATHIOPRINE",
  "CICLOSPORIN",
  "TACROLIMUS",
  "SIROLIMUS",
  "FINGOLIMOD",
  "CHLORPHENIRAMINE",
  "TRIPROLIDINE",
  "KETOTIFEN",
  "DIPHENHYDRAMINE",
  "PROMETHAZINE",
  "CETIRIZINE",
  "LORATADINE",
  "FEXOFENADINE",
  "LEVOCETIRIZINE",
  "DESLORATIDINE",
  "OXYMETAZOLINE",
  "PHENYLEPHRINE",
  "BRIMONIDINE",
  "MIRABEGRON",
  "SALBUTAMOL",
  "TERBUTALINE",
  "DOBUTAMINE",
  "DOPAMINE",
  "NOREPINEPHRINE",
  "ADRENALINE",
  "MOCLOBEMIDE",
  "IMIPRAMINE",
  "REBOXETINE",
  "PRAZOSIN",
  "TAMSULOSIN",
  "TIMOLOL",
  "PROPRANOLOL",
  "SOTALOL",
  "BISOPROLOL",
  "BETAXOLOL",
  "METHYLDOPA",
  "CLONIDINE",
  "PILOCARPINE",
  "BETHANECHOL",
  "NICOTINE",
  "VARENICLINE",
  "DONEPEZIL",
  "NEOSTIGMINE",
  "PHYSOSTIGMINE",
  "PYRIDOSTIGMINE",
  "PRALIDOXIME",
  "ATROPINE",
  "BENZTROPINE",
  "HYOSCINE",
  "OXYBUTYNIN",
  "IPRATROPIUM",
  "ROCURONIUM",
  "PANCURONIUM",
  "SUXAMETHONIUM",
  "THALIDOMIDE",
  "DIAZEPAM",
  "PROPOFOL",
  "TRAMADOL",
  "HALOTHANE",
  "ISOFLURANE",
  "SEVOFLURANE",
  "PROPOFOL",
  "KETAMINE",
  "MIDAZOLAM",
  "COCAINE",
  "PROCAINE",
  "TETRACAINE",
  "BENZOCAINE",
  "LIDOCAINE",
  "MEPIVACAINE",
  "BUPIVACAINE",
  "PRILOCAINE",
  "CODEINE",
  "MORPHINE",
  "DIPHENOXYLATE",
  "FENTANYL",
  "METHADONE",
  "PETHIDINE",
  "TRAMADOL",
  "NALOXONE",
  "NALTREXONE",
  "NALMEFINE"
]
export const ANTIMICROBIAL = [
  "PENICILLIN",
  "CLOXACILLIN",
  "AMOXICILLIN",
  "AMPICILLIN",
  "PIPERACILLIN",
  "CEFAZOLIN",
  "CEPHALEXIN",
  "CEFTRIAXONE",
  "CEFEPIME",
  "ERTAPENEM",
  "AZTREONAM",
  "VANCOMYCIN",
  "TETRACYCLINE",
  "DOXYCYCLINE",
  "MINOCYCLINE",
  "TIGECYCLINE",
  "GENTAMICIN",
  "AMIKACIN",
  "TOBRAMYCIN",
  "STREPTOMYCIN",
  "NEOMYCIN",
  "ERYTHROMYCIN",
  "CLARITHROMYCIN",
  "AZITHROMYCIN",
  "CLINDAMYCIN",
  "LINEZOLID",
  "CIPROFLOXACIN",
  "LEVOFLOXACIN",
  "MOXIFLOXACIN",
  "SULFAMETHOXAZOLE",
  "TRIMETHOPRIM",
  "COTRIMOXAZOLE",
  "NITROFURANTOIN",
  "METRONIDAZOLE",
  "FLUCONAZOLE",
  "POSACONAZOLE",
  "ITRACONAZOLE",
  "VORICONAZOLE",
  "CLOTRIMAZOLE",
  "MICONAZOLE",
  "NYSTATIN",
  "TERBINAFINE",
  "ABACAVIR",
  "EMTRICITABINE",
  "LAMIVIDINE",
  "TENOFIVIR",
  "ZIDOVUDINE",
  "ACICLOVIR",
  "RIFAMPICIN",
  "ISONIAZID",
  "PYRAZINAMIDE",
  "ETHAMBUTOL",
  "ALBENDAZOLE",
  "IVERMECTIN"
]
export const CANCER = [
  "METHOTREXATE",
  "DAUNORUBICIN",
  "IDARUBICIN",
  "EPIRUBICIN",
  "VINBLASTINE",
  "PACLITAXEL",
  "IRINOTECAN",
  "ETPOSIDE",
  "AMSACRINE",
  "TENIPOSIDE",
  "IMATINIB",
  "BEVACIZUMAB",
  "BRENTUXIMAB",
  "IPILIMUMAB",
  "NIVOLUMAB",
  "PEMBROLIZUMAB"
]
export const CARDIOVASCULAR = [
  "LISINOPRIL",
  "ENALAPRIL",
  "LOSARTAN",
  "CANDESARTAN",
  "CARVEDILOL",
  "BISOPROLOL",
  "METOPROLOL",
  "NEBIVOLOL",
  "PROPRANOLOL",
  "ATENOLOL",
  "INDAPAMIDE",
  "PRAZOSIN",
  "FUROSEMIDE",
  "SPIRONOLACTONE",
  "EPLERENONE",
  "TRIAMTERENE",
  "AMILORIDE",
  "HYDRALAZINE",
  "IVABRADINE",
  "NIFEDIPINE",
  "AMLODIPINE",
  "VERAPAMIL",
  "DILTIAZEM",
  "NITROGLYCERIN",
  "PROCAINAMIDE",
  "LIDOCAINE",
  "FLECAINIDE",
  "AMIODARONE",
  "VERAPAMIL",
  "ADENOSINE",
  "ATORVASTATIN",
  "SIMVASTATIN",
  "EVOLUCUMAB",
  "ALIROCUMAB",
  "FENOFIBRATE",
  "COLESTYRAMINE",
  "EZETIMIBE",
  "VYTORIN",
  "ASPIRIN",
  "ABCIXIMAB",
  "EPTIFIBATIDE",
  "TIROFIBAN",
  "DIPYRIDAMOLE",
  "CLOPIDOGREL",
  "TICLOPIDINE",
  "HEPARIN",
  "WARFARIN",
  "ALTEPLASE",
  "STREPTOKINASE"
]
export const CNS = [
  "DIAZEPAM",
  "LORAZEPAM",
  "MIDAZOLAM",
  "ALPRAZOLAM",
  "OLANZAPINE",
  "RISPERIDONE",
  "HALOPERIDOL",
  "CLOZAPINE",
  "QUETIAPINE",
  "ESCITALOPRAM",
  "FLUOXETINE",
  "FLUVOXAMINE",
  "PAROXETINE",
  "SERTRALINE",
  "IMIRPAMINE",
  "AMITRPTYLINE",
  "CARBAMAZEPINE",
  "PHENYTOIN",
  "PHENBARBITONE",
  "CARBAMAZEPINE"
]
export const ENDOCRINE = [
  "METFORMIN",
  "GLIPIZIDE",
  "GLICLAZIDE",
  "GLIBENCLAMIDE",
  "PIOGLITAZONE",
  "REPAGLINIDE",
  "ACARBOSE",
  "SITAGLIPTIN",
  "LINAGLIPTIN",
  "LIRAGLUTIDE",
  "SEMAGLUTIDE",
  "EMPAGLIFLOZIN",
  "DAPAGLIFLOZIN",
  "LISPRO",
  "ASPART",
  "GLULISINE",
  "GLARGINE",
  "DETEMIR",
  "LEVOTHYROXINE",
  "LIOTHYRONINE",
  "PROPYLTHIOURACIL",
  "CARBIMAZOLE",
  "IODIDE"
]
export const GASTROINTESTINAL = [
  "CIMETIDINE",
  "RANITIDINE",
  "FAMOTIDINE",
  "OMEPRAZOLE",
  "ESOMEPRAZOLE",
  "VONOPRAZAN",
  "SUCRALFATE",
  "MISOPROSTOL",
  "BISMUTH",
  "CLARITHROMYCIN",
  "AMOXICILLIN",
  "METRONIDAZOLE",
  "PSYLLIUM",
  "GLYCERIN",
  "SORBITOL",
  "LACTULOSE",
  "MACROGOL",
  "ALOE",
  "SENNA",
  "BISACODYL",
  "LOPERAMIDE",
  "DIPHENOXYLATE",
  "KAOLIN",
  "PECTIN",
  "BISMUTH",
  "COLESTYRAMINE",
  "OCTREOTIDE",
  "ONDANSETRON",
  "PALONOSETRON",
  "DEXAMETHASONE",
  "METOCLOPRAMIDE",
  "DOMPERIDONE",
  "APREPITANT",
  "FOSAPREPITANT",
  "PROCHLORPERAZINE",
  "PROMETHAZINE",
  "DROPERIDOL",
  "OLANZAPINE",
  "LORAZEPAM",
  "DIAZEPAM",
  "CINNARIZINE",
  "DOXYLAMINE"
]
export const MUSCULOSKELETAL = [
  "PREDNISOLONE",
  "NAPROXEN",
  "INDOMETACIN",
  "CELECOXIB",
  "ETORICOXIB",
  "COLCHICINE",
  "ALLOPURINOL",
  "FEBUXOSTAT",
  "PROBENECID",
  "CAPSAICIN",
  "PARACETAMOL",
  "DICLOFENAC",
  "NAPROXEN",
  "MELOXICAM",
  "HYDROCORTISONE",
  "GLUCOSAMINE",
  "METHOTREXATE",
  "SULFASALAZINE",
  "LEFLUNOMIDE",
  "CHLOROQUINE",
  "CICLOSPORIN",
  "TOFACITINIB",
  "BARICITINIB",
  "RUXOLITINIB",
  "INFLIXIMAB",
  "ADALIMUMAB",
  "GOLIMUMAB",
  "ETANERCEPT",
  "ANAKINRA",
  "TOCILIZUMAB",
  "ABATACEPT",
  "RITUXIMAB",
  "RISEDRONATE",
  "ALENDRONATE",
  "DENOSUMAB",
  "RALOXIFENE",
  "CALCITONIN",
  "TERIPARATIDE",
  "CALCIUM"
];
export const RESPIRATORY = [
  "GUANIFENESIN",
  "BROMOHEXINE",
  "AMBROXOL",
  "ACETYLCYSTEINE",
  "CARBOCISTEINE",
  "CODEINE",
  "DEXTROMETHORPHAN",
  "DIPHENHYDRAMINE",
  "TRIPROLIDINE",
  "PROMETHAZINE",
  "CETIRIZINE",
  "LEVOCETIRIZINE",
  "LORATADINE",
  "DESLOTRADINE",
  "FEXOFENADINE",
  "IPRATROPIUM",
  "FLUTICASONE",
  "MOMETASONE",
  "PHENYLEPHRINE",
  "OXYMETAZOLINE",
  "EPHEDRINE",
  "PSEUDOEPHEDRINE",
  "EPINEPHRINE",
  "SALBUTAMOL",
  "FORMOTEROL",
  "SALMETEROL",
  "TIOTROPIUM",
  "THEOPHYLLINE",
  "AMINOPHYLLINE",
  "MONTELUKAST",
  "ZILEUTON",
  "BUDESONIDE",
  "CICLESONIDE",
  "OMALIZUMAB",
  "RESLIZUMAB",
  "DUPILUMAB",
  "INDACATEROL",
  "OLODATEROL",
  "GLYCOPYRRONIUM",
  "ERDOSTEINE",
  "ROFLUMILAST",
  "AZITHROMYCIN",
  "BUPROPION",
  "NORTRIPTYLINE"
];
