import { createContext, useCallback, useEffect, useState } from "react";
import { 
  /*TESTWORDLENGTH,*/
  FOUNDATIONAL,
  ANTIMICROBIAL,
  CANCER, 
  CARDIOVASCULAR,
  CNS, 
  ENDOCRINE,
  GASTROINTESTINAL,
  RESPIRATORY,
  MUSCULOSKELETAL
  } from "../constants";

const WORD_SOURCES = {
  /*"test word length" : TESTWORDLENGTH,*/
  "foundational" : FOUNDATIONAL,  
  "antimicrobial" : ANTIMICROBIAL,
  "cancer biology" : CANCER,
  "cardiovascular system" : CARDIOVASCULAR,
  "central nervous system" : CNS,
  "endocrine system" : ENDOCRINE,
  "gastrointestinal system" : GASTROINTESTINAL,
  "musculoskeletal system": MUSCULOSKELETAL,
  "respiratory system": RESPIRATORY
};
const wordsources = [
 /* "test word length",*/
  "foundational", 
  "antimicrobial", 
  "cancer biology",
  "cardiovascular system",
  "central nervous system",
  "endocrine system", 
  "gastrointestinal system",
  "musculoskeletal system", 
  "respiratory system"
];

export const WordsourceContext = createContext();

const getRandomWord = (source) =>
  source[Math.floor(Math.random() * source.length)];

export const WordsourceContextProvider = ({ children }) => {
  const [wordsource, setWordsource] = useState(wordsources[0]);
  const [guessword, setGuessWord] = useState(
    getRandomWord(WORD_SOURCES[wordsources[0]])
  );

  const resetGuessword = useCallback(() => {
    setGuessWord(getRandomWord(WORD_SOURCES[wordsource]));
  }, [setGuessWord, wordsource]);

  useEffect(() => {
    resetGuessword();
  }, [wordsource, resetGuessword]);

  return (
    <WordsourceContext.Provider
      value={{
        wordsource,
        guessword,
        setWordsource,
        setGuessWord,
        wordsources,
        resetGuessword
      }}
    >
      {children}
    </WordsourceContext.Provider>
  );
};
