import "./style.css"

export default function Stage({ step, wordSource }) {
  return (
    <div className="stage">
      <HangmanSVG step={step} />
      <label>Guess the {wordSource} drug...</label>
      {/* 
      <small>
        (you can also use keyboard, press [space] or [enter] to reset)
      </small>
     */}
    </div>
  );
}

function getDisplayValue(step, target) {
  return step > target ? "inline" : "none";
}
function HangmanSVG({ step }) {
  return (
<svg height="150" width="400" viewBox="0 0 300 300" strokeWidth="4">

<g transform="translate(-145, 10) scale(1.2)">
  <path d="M0 24 350 24" stroke="#00B050" stroke-width="4" stroke-linejoin="round" stroke-miterlimit="10" stroke-dasharray="8 6" fill="none" fill-rule="evenodd"/>
  <path d="M0 161 350 161" stroke="#EF7C00" stroke-width="4" stroke-linejoin="round" stroke-miterlimit="10" stroke-dasharray="8 6" fill="none" fill-rule="evenodd"/>
  <path d="M0 0 0 200" /* stroke="#033D7C" */ stroke-width="8" stroke-miterlimit="8" fill="none" fill-rule="evenodd" />
  <path d="M-4 200 370 200" /* stroke="#033D7C" */ stroke-width="8" stroke-miterlimit="8" fill="none" fill-rule="evenodd"/>
  <text /* font-family="Aptos,Aptos_MSFontService,sans-serif" */ stroke-width="1.5" font-size="20" transform="translate(158.675 229)">Time</text>
  <text /* font-family="Aptos,Aptos_MSFontService,sans-serif" */ stroke-width="1.5" font-size="20" transform="translate(-40 135) rotate(-90 0 0)">Plasma</text>
  <text /* font-family="Aptos,Aptos_MSFontService,sans-serif" */ stroke-width="1.5" font-size="20" transform="translate(-15 170) rotate(-90 0 0)">Concentration</text>
  <text /* font-family="Aptos,Aptos_MSFontService,sans-serif" */ stroke-width="1.5" font-size="20" x="350" y="141" dominant-baseline="middle" text-anchor="middle">Minimum</text>
  <text /* font-family="Aptos,Aptos_MSFontService,sans-serif" */ stroke-width="1.5" font-size="20" x="350" y="161" dominant-baseline="middle" text-anchor="middle">Effective</text>
  <text /* font-family="Aptos,Aptos_MSFontService,sans-serif" */ stroke-width="1.5" font-size="20" x="350" y="181" dominant-baseline="middle" text-anchor="middle">Concentration</text>
  <path d="M0.5 30.04C4.524 29.81 13.828 12.314 21.403 13.199 28.978 14.083 37.579 35.462 45.948 35.347 54.317 35.231 62.753 12.314 71.616 12.507 80.479 12.699 90.645 36.5 99.126 36.5 107.606 36.5 112.949 12.045 122.5 12.507" /* stroke="#033D7C" */ s stroke-width="8" stroke-miterlimit="8" fill="none" fill-rule="evenodd"/>
 {/* <text font-family="Aptos,Aptos_MSFontService,sans-serif" stroke-width="1.5" font-size="20" transform="translate(320 17)">Steady</text> */}
 {/* <text font-family="Aptos,Aptos_MSFontService,sans-serif" stroke-width="1.5" font-size="20" transform="translate(330 43)">State</text> */}
  <text /* font-family="Aptos,Aptos_MSFontService,sans-serif" */ stroke-width="1.5" font-size="20" x="350" y="15" dominant-baseline="middle" text-anchor="middle">Steady</text>
  <text /* font-family="Aptos,Aptos_MSFontService,sans-serif" */ stroke-width="1.5" font-size="20" x="350" y="35" dominant-baseline="middle" text-anchor="middle">State</text>
</g>


      <g transform="translate(260, 0)" id="head" display={getDisplayValue(step, 0)}>
        <circle cx="200" cy="85" r="24" fill="none" />
        <path transform="translate(-405, 10) scale(1.2)" d="M 122.5 12.537 C 131.513 26.283, 139.019 33.282, 140.526 40.029"  /* stroke="#033D7C" */ s stroke-width="8" stroke-miterlimit="8" fill="none" fill-rule="evenodd"/>
        {/* <g id="eyes">
            <circle cx="193" cy="80" r="4" />
            <circle cx="207" cy="80" r="4" />
          </g> */}
        <g transform="translate(0, 3)" 
          display={getDisplayValue(step, 6)}
          strokeWidth="3"
          strokeLinecap="square"
        >
          <line x1="190" y1="78" x2="196" y2="84" />
          <line x1="204" y1="78" x2="210" y2="84" />
          <line x1="190" y1="84" x2="196" y2="78" />
          <line x1="204" y1="84" x2="210" y2="78" />
          <path transform="translate(-405, 7) scale(1.2)" d="M122.5 12.537C142.217 10.025 145.033 135.628 291.5 167.5" /* stroke="#033D7C" */ s stroke-width="8" stroke-miterlimit="8" fill="none" fill-rule="evenodd"/>
        </g>
      </g>
      <g transform="translate(260, 0)" id="body" strokeLinecap="butt">
        <line
          display={getDisplayValue(step, 1)}
          x1="200"
          y1="107"
          x2="200"
          y2="170"
        />
        <path display={getDisplayValue(step, 1)} transform="translate(-405, 10) scale(1.2)" d="M 140.526 40.029 C 147.589 50.344, 151.120 60.501, 155.652 70.658"  /* stroke="#033D7C" */ s stroke-width="8" stroke-miterlimit="8" fill="none" fill-rule="evenodd"/>
        <line
          display={getDisplayValue(step, 2)}
          x1="200"
          y1="110"
          x2="155"
          y2="130"
        />
        <path display={getDisplayValue(step, 2)} transform="translate(-405, 10) scale(1.2)" d="M 155.652 70.658 C 165.206 84.773, 169.981 91.829, 174.761 98.889"  /* stroke="#033D7C" */ s stroke-width="8" stroke-miterlimit="8" fill="none" fill-rule="evenodd"/>
        <line
          display={getDisplayValue(step, 3)}
          x1="200"
          y1="110"
          x2="245"
          y2="130"
        />
        <path display={getDisplayValue(step, 3)} transform="translate(-405, 10) scale(1.2)" d="M 174.761 98.889 C 186.628 111.120, 191.561 117.236, 198.494 123.351"  /* stroke="#033D7C" */ s stroke-width="8" stroke-miterlimit="8" fill="none" fill-rule="evenodd"/>
        <line
          display={getDisplayValue(step, 4)}
          x1="200"
          y1="170"
          x2="160"
          y2="220"
        />
        <path display={getDisplayValue(step, 4)} transform="translate(-405, 10) scale(1.2)" d="M 198.494 123.351 C 212.415 133.174, 219.875 138.085, 226.337 142.997"  /* stroke="#033D7C" */ s stroke-width="8" stroke-miterlimit="8" fill="none" fill-rule="evenodd"/>
        <line
          display={getDisplayValue(step, 5)}
          x1="200"
          y1="170"
          x2="240"
          y2="220"
        />
        <path display={getDisplayValue(step, 5)} transform="translate(-405, 10) scale(1.2)" d="M 226.337 142.997 C 241.751 150.237, 249.464 153.857, 257.166 157.477"  /* stroke="#033D7C" */ s stroke-width="8" stroke-miterlimit="8" fill="none" fill-rule="evenodd"/>
        <path display={getDisplayValue(step, 6)} transform="translate(-405, 10) scale(1.2)" d="M 257.166 157.477 C 273.457 162.295, 281.625 164.409, 289.749 167.113"   /* stroke="#033D7C" */ s stroke-width="8" stroke-miterlimit="8" fill="none" fill-rule="evenodd"/>
      </g>
      <g transform="translate(270, 0)" id="pole-rope" strokeWidth="10" strokeLinecap="round">
        <line x1="65" y1="250" x2="240" y2="250" />
        <line x1="110" y1="250" x2="110" y2="20" />
        <line id="h-beam" x1="110" y1="20" x2="220" y2="20" />
        <line
          id="x-beam"
          strokeLinecap="butt"
          x1="110"
          y1="70"
          x2="160"
          y2="20"
        />
        <line
          strokeWidth="4"
          strokeDasharray="6,6"
          x1="190"
          y1="20"
          x2="190"
          y2="60"
        />
      </g>
</svg>
/*
<svg>
      <g>
      <path d="M0 0 0 200" stroke="#033D7C" stroke-width="2" stroke-miterlimit="8" fill="none" fill-rule="evenodd" transform="matrix(-1 0 0 1 221 263)"/>
      <path d="M221 464 588.579 464" stroke="#033D7C" stroke-width="2" stroke-miterlimit="8" fill="none" fill-rule="evenodd"/>
      <text font-family="Aptos,Aptos_MSFontService,sans-serif" font-weight="400" font-size="24" transform="translate(379.675 492)">Time</text>
      <text font-family="Aptos,Aptos_MSFontService,sans-serif" font-weight="400" font-size="24" transform="matrix(-1.83697e-16 -1 1 -1.83697e-16 179.996 402)">Plasma</text>
      <text font-family="Aptos,Aptos_MSFontService,sans-serif" font-weight="400" font-size="24" transform="matrix(-1.83697e-16 -1 1 -1.83697e-16 208.996 436)">concentration</text>
      <path d="M221 414 588.579 414" stroke="#EF7C00" stroke-width="2" stroke-linejoin="round" stroke-miterlimit="10" stroke-dasharray="8 6" fill="none" fill-rule="evenodd"/>
      <text font-family="Aptos,Aptos_MSFontService,sans-serif" font-weight="400" font-size="24" transform="translate(518.216 407)">Therapeutic</text>
      <text font-family="Aptos,Aptos_MSFontService,sans-serif" font-weight="400" font-size="24" transform="translate(532.689 436)">threshold</text>
      <path d="M221.5 293.04C225.524 292.81 234.828 275.314 242.403 276.199 249.978 277.083 258.579 298.462 266.948 298.347 275.317 298.231 283.753 275.314 292.616 275.507 301.479 275.699 311.645 299.5 320.126 299.5 328.606 299.5 333.949 275.045 343.5 275.507" stroke="#156082" stroke-width="1.33333" stroke-miterlimit="8" fill="none" fill-rule="evenodd"/>
      <path d="M221 287 617.632 287" stroke="#00B050" stroke-width="2" stroke-linejoin="round" stroke-miterlimit="10" stroke-dasharray="8 6" fill="none" fill-rule="evenodd"/>
      <text font-family="Aptos,Aptos_MSFontService,sans-serif" font-weight="400" font-size="24" transform="translate(550.691 277)">Steady</text>
      <text font-family="Aptos,Aptos_MSFontService,sans-serif" font-weight="400" font-size="24" transform="translate(562.498 306)">stat</text>
      <text font-family="Aptos,Aptos_MSFontService,sans-serif" font-weight="400" font-size="24" transform="translate(602.098 306)">e</text>
      <path d="M343.5 275.537C363.217 273.025 366.033 398.628 512.5 430.5" stroke="#156082" stroke-width="1.33333" stroke-miterlimit="8" fill="none" fill-rule="evenodd"/>
      </g>


      </svg>
      */
  );
}
