import "./style.css";
import React, { useEffect } from 'react';

function GuessLetter({ letter, rightGuesses }) {
  return (
    <span className={`guess-letter neumorph invert disabled`}>
      {rightGuesses.includes(letter) ? letter : "-"}
    </span>
  );
}

function Guessword({ rightGuesses, guessword }) {
  const width = (19/guessword.length) > 2 ? 2 : (19/guessword.length);
  useEffect(() => {
    document.documentElement.style.setProperty('--dynamic-width', `${width}rem`);
  }, [width]);

  return (
    <div className="guessword">
      {guessword.split("").map((letter, index) => (
        <GuessLetter
          key={letter + index}
          letter={letter}
          rightGuesses={rightGuesses}
        />
      ))}
    </div>
  );
}

export default Guessword;


/*import "./style.css";

function GuessLetter({ letter, rightGuesses }) {
  return (
    <span className={`guess-letter neumorph invert disabled`}>
      {rightGuesses.includes(letter) ? letter : "-"}
    </span>
  );
}

export default function Guessword({ rightGuesses, guessword }) {
  return (
    <div className="guessword">
      {guessword.split("").map((letter, index) => (
        <GuessLetter
          key={letter + index}
          letter={letter}
          rightGuesses={rightGuesses}
        />
      ))}
    </div>
  );
}
*/