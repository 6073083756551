import { useCallback } from "react";
import { useDarkMode } from "../../hooks";
// Icons used in this component are from Heroicons v2 (outlined style): https://heroicons.com/
import {
  SunIcon,
  MoonIcon,
} from '@heroicons/react/24/outline'  // Importing outlined icons from Heroicons v2
import "./style.css";

// Creating a React component for the SunIcon with specific styling
const Sun = () => <SunIcon className="transparent-icon" />

// Creating a React component for the MoonIcon with specific styling
const Moon = () => <MoonIcon className="transparent-icon" />

// Exporting the Sun and Moon components for use elsewhere in the application
export { Sun, Moon };

export default function ThemeToggle() {
  const { darkMode, setDarkMode } = useDarkMode();
  const onToggle = useCallback(() => {
    setDarkMode((theme) => !theme);
  }, [setDarkMode]);

  const themeSymbol = darkMode ? <Sun /> : <Moon />;
  return (
    <span className="theme-toggle neumorph" onClick={onToggle}>
      {themeSymbol}
    </span>
  );
}
